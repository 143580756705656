<template>
  <div class="typeArea">
    <div class="top">
      <div class="top-header">
        版权声明
      </div>
      <div class="top-content">
        <div class="content-item">
          凡本网注明“来源：科研人”的所有作品，均为科研人（www.keyanpro.com）合法拥有版权或有权使用的作品，未经本网授权不得转载、摘编或利用其它方式使用上述作品。已经本网授权使用作品的，应在授权范围内使用，并注明“来源：科研人”。违反上述声明者，本网将追究其相关法律责任。
        </div>
        <div class="content-item">
          凡本网注明“来源：XXX（非科研人）”的作品，均转载自其它媒体，转载目的在于传递更多信息，并不代表本网赞同其观点和对其真实性负责。
        </div>

      </div>
    </div>
    <div class="bottom">
      <div class="bottom-header">
        网站使用条款
      </div>
      <div class="bottom-content">
        <div class="content-header">
          一、定义
        </div>
        <div class="content-text">
          除本协议另有规定外，下列用语或术语应当具有以下定义：
        </div>
        <div class="content-text">
          科研人或科研人平台指域名为www.keyanpro.com的网站。
        </div>
        <div class="content-text">
          用户，指符合科研人规定的条件、同意遵守本协议的约定，自愿申报注册、取得科研人账号并被许可使用科研人平台服务的主体。
        </div>
        <div class="content-text">
          协议或本协议，指科研人拟定的《科研人平台用户协议》。
        </div>
        <div class="content-header">
          二、声明与承诺
        </div>
        <div class="content-text">
          2.1 科研人在此特别声明，用户欲使用科研人服务，必须事先认真阅读本协议全部条款（未成年人阅读时应得到其监护人的陪同），特别是本协议中免除、减轻或者限制科研人责任的全部条款以及含有限制用户权利内容的全部条款，请特别关注第七条“隐私条款”和第八条“免责声明”。
        </div>
        <div class="content-text">
          2.2 用户承诺其点击科研人注册页面的同意注册按钮并完成注册程序、获得科研人账号和密码时，视为与科研人已达成《科研人平台用户协议》，并完全同意协议所有条款。用户下载、注册、登录、使用及连接科研人服务等行为将被视为用户完全了解、接受并同意遵守本协议项下的全部内容。本协议可由科研人单方随时修改，修改后的协议条款一经科研人公布即代替本协议的原条款，构成用户与科研人之间的最新协议。用户可以随时在科研人平台中查阅最新协议条款。如果用户不接受科研人修改后的最新协议条款，请立即停止使用科研人服务。
        </div>
        <div class="content-text">
          2.3 用户在此承诺，已经完全理解本协议相关内容，并不存在任何重大误解；同时，认可协议内容并不存在有失公平的情形。
        </div>
        <div class="content-text">
          2.4 用户承诺并保证妥善保管其在科研人平台的账号和密码，并对其账户中的所有活动和事件承担责任。用户有权随时修改其密码和图标，也可注销旧的帐户开立新帐户。用户若发现任何非法使用其帐号或存在安全漏洞的情况，应立即通知科研人并采取措施以减少不必要的损失。
        </div>
        <div class="content-text">
          2.5 用户承诺并保证其提供或发布的所有信息是真实、准确、完整、及时、有效、合法的，符合中国各项法律、法规及规范性文件以及科研人相关协议的规定，不存在虚假、误导性陈述或重大遗漏，并承诺及时对已发布的信息进行更新、维护和管理。
        </div>
        <div class="content-text">
          2.6 用户承诺并保证若其违反上述声明和承诺而给科研人造成损失，同意对科研人因此而遭受的损失承担相应的赔偿责任。
        </div>
        <div class="content-header">
          三、服务内容
        </div>
        <div class="content-text">
          3.1 科研人是为院所、高校科研人员等用户提供第三方服务的专业网站，旨在为科研人员提供信息情报获取平台。
        </div>
        <div class="content-text">
          3.2 科研人目前通过互联网向用户提供以下服务：科研资讯、科研课题、科研项目。除非另有明确规定，增加或强化科研人服务的任何新功能，包括推出的新产品，均受本协议条款之规范。
        </div>
        <div class="content-header">
          四、用户个人信息保护
        </div>
        <div class="content-text">
          4.1 科研人与用户一同致力于用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护。科研人将运用与相关服务相匹配的安全技术及其他安全措施来保护用户的个人信息。
        </div>
        <div class="content-text">
          4.2 用户可随时浏览、修改自己提交的个人身份信息，用户理解并同意出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，用户可能无法修改注册时提供的初始注册信息及其他验证信息。
        </div>
        <div class="content-text">
          4.3 请用户注意勿在使用科研人相关服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。
        </div>
        <div class="content-header">
          五、用户行为规范义务
        </div>
        <div class="content-text">
          5.1 用户不得实施有损于科研人的行为，包括但不限于以下情形： 5.2 用户有5.1或如下行为之一的，科研人平台有权单方终止本协议，立即停止对用户发布信息的技术支持和其他服务，并保存有关记录、删除含有有关信息的地址、目录或关闭服务器，必要时可依据中国法律、法规及规范性文件向国家有关机关报告。用户应承担全部法律责任，并赔偿科研人及其他任何第三方由此受到的损失，包括合理的追索费用： （1） 用户提供的信息/产品侵害任何第三方知识产权、财产权等合法权益，或可能对第三方造成不利影响或侵害； （2） 用户利用科研人平台提供的网络平台服务进行任何违法犯罪活动，或宣传散播法律法规禁止散播的信息，或实施其他损害科研人平台利益的行为； （3） 用户有违反本协议及科研人制定的其他相关制度、协议的行为，经科研人警告后在一定期限内仍未改正； （4） 用户的行为严重干扰科研人正常的服务活动。
        </div>
        <div class="content-text-indent">
          （1） 未经科研人书面许可，通过本协议约定方式之外的途径进入科研人计算机信息网络系统或者使用本协议约定范围外的信息网络资源或服务；
        </div>
        <div class="content-text-indent">
          （2） 未经科研人书面许可，对科研人平台计算机信息网络中存储、处理或者传输的数据和应用程序、网络功能进行删除、修改、增加、反向工程、反编译、反汇编或者以其他方式尝试发现软件的源代码；
        </div>
        <div class="content-text-indent">
          （3） 故意制作、传播计算机病毒等破坏性程序；
        </div>
        <div class="content-text-indent">
          （4） 对本平台进行扫描、探查、测试，以检测、发现、查找其中可能存在的BUG或弱点；
        </div>
        <div class="content-text-indent">
          （5）发布以下信息：1）违反中国各项法律、法规及规范性文件规定的；2）危害国家安全、泄露国家秘密、颠覆国家政权、破坏国家统一的，煽动民族仇恨、民族歧视，破坏民族团结的，破坏国家宗教政策、宣扬邪教和封建迷信的，有损国家荣誉和利益的；3）散布谣言、含有淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪信息，扰乱社会秩序，破坏社会稳定的；4）侮辱或者诽谤他人，侵犯其他任何第三方知识产权及其他合法权益的；5）其他含有法律、法规及规范性文件禁止内容的；
        </div>
        <div class="content-text-indent">
          （6） 其他危害计算机信息网络安全、妨碍科研人正常运作、有损科研人公信力的行为。此带来的任何损失由用户自行承担。
        </div>
        <div class="content-text">
          5.2 用户有5.1或如下行为之一的，科研人平台有权单方终止本协议，立即停止对用户发布信息的技术支持和其他服务，并保存有关记录、删除含有有关信息的地址、目录或关闭服务器，必要时可依据中国法律、法规及规范性文件向国家有关机关报告。用户应承担全部法律责任，并赔偿科研人及其他任何第三方由此受到的损失，包括合理的追索费用：
        </div>
        <div class="content-text-indent">
          （1） 用户提供的信息/产品侵害任何第三方知识产权、财产权等合法权益，或可能对第三方造成不利影响或侵害； （2） 用户利用科研人平台提供的网络平台服务进行任何违法犯罪活动，或宣传散播法律法规禁止散播的信息，或实施其他损害科研人平台利益的行为； （3） 用户有违反本协议及科研人制定的其他相关制度、协议的行为，经科研人警告后在一定期限内仍未改正； （4） 用户的行为严重干扰科研人正常的服务活动。
        </div>
        <div class="content-text-indent">
          （2） 用户利用科研人平台提供的网络平台服务进行任何违法犯罪活动，或宣传散播法律法规禁止散播的信息，或实施其他损害科研人平台利益的行为； （3） 用户有违反本协议及科研人制定的其他相关制度、协议的行为，经科研人警告后在一定期限内仍未改正； （4） 用户的行为严重干扰科研人正常的服务活动。
        </div>
        <div class="content-text-indent">
          （3） 用户有违反本协议及科研人制定的其他相关制度、协议的行为，经科研人警告后在一定期限内仍未改正； （4） 用户的行为严重干扰科研人正常的服务活动。
        </div>
        <div class="content-text-indent">
          （4） 用户的行为严重干扰科研人正常的服务活动。
        </div>
        <div class="content-header">
          六、知识产权
        </div>
        <div class="content-text">
          6.1 科研人是科研人平台的知识产权权利人，对科研人平台上所有产品享有所有权，包括但不限于平台发布的信息内容（包括但不限于界面设计、版面框架、数据资料、账号、文字、图片、图形、图表、音频、视频等，但按照法律法规规定应由相关权利人享有权利的内容除外）、程序代码、关系链拓展、便捷辅助工具、平台应用程序等。
        </div>
        <div class="content-text">
          6.2 鉴于以上，用户理解并同意：
        </div>
        <div class="content-text-indent">
          （1）未经科研人及相关权利人同意，用户不得对上述功能、软件、服务进行反向工程 （reverse engineer）、反向编译（decompile）或反汇编（disassemble）等；同时，不得将上述内容或资料在任何媒体直接或间接发布、播放、出于播放或发布目的而改写或再发行，或者用于其他任何目的。
        </div>
        <div class="content-text-indent">
          （2） 在尽商业上合理努力的前提下，科研人并不就上述功能、软件、服务及其所包含内容的延误、不准确、错误、遗漏或由此产生的任何损害，以任何形式向用户或任何第三方承担任何责任。
        </div>
        <div class="content-header">
          七、隐私条款
        </div>
        <div class="content-text">
          7.1 用户在此知悉并同意：用户应谨慎填写和上传相关信息，并保证填写和上传信息的真实性、完整性、准确性。本平台会把用户提供的信息作为重要参考信息，并根据本平台的项目审核评定标准决定是否展示及展示何种信息，以帮助用户获得本平台的服务。
        </div>
        <div class="content-text">
          7.2 本隐私条款不适用的情形：
        </div>
        <div class="content-text">
          7.2.1 通过科研人及相关服务而接入的第三方服务（包括任何第三方应用及网站）收集的信息；
        </div>
        <div class="content-text">
          7.2.2 通过在科研人及相关服务中进行广告服务的其他公司或机构、组织所收集的信息；
        </div>
        <div class="content-text">
          7.2.3 科研人的服务可能包括或链接至第三方提供的信息或其他服务（包括网站）。该等第三方服务可能由相关的第三方运营。用户使用该等第三方服务（包括用户向该等第三方提供的任何个人信息），须受该第三方的服务条款及隐私政策（而非本隐私政策）约束，用户需要仔细阅读其条款。请用户妥善保护自己的个人信息，仅在必要的情况下向他人提供。本隐私政策仅适用于公司所收集、保存、使用的信息，并不适用于任何第三方提供的服务或第三方的信息使用协议，科研人对任何第三方使用由用户提供的信息不承担任何责任。
        </div>
        <div class="content-header">
          八、免责声明
        </div>
        <div class="content-text">
          8.1 用户应承担其注册账号项下任何行为产生的后果和责任，非因科研人原因导致的用户账号被盗，科研人不承担任何责任。
        </div>
        <div class="content-text">
          8.2 科研人依照业务协议限制、冻结或终止用户的账号使用，可能会给用户造成一定的损失，该损失由用户自行承担，科研人不承担任何责任。
        </div>
        <div class="content-text">
          8.3 用户不得有偿或无偿转让本平台下账号，以免产生纠纷。用户应当自行承担由此产生的责任，同时科研人保留追究上述行为人法律责任的权利。
        </div>
        <div class="content-text">
          8.4 科研人仅为用户提供服务的信息平台，并不承诺与保证满足用户的所有需求。
        </div>
        <div class="content-text">
          8.5 科研人平台的信息来源于公开渠道、用户主动披露以及根据公开渠道或用户主动披露获取的信息整理、推算的结果，本平台信息仅供用户参考，不作为向用户提供投资、法律、审计、评估、财务、税务等事项的依据性文件或信息。用户应充分理解并同意对本平台提供的内容进行核实和判断，并自行承担因使用本平台产品或服务而引起的风险，科研人不对此承担法律义务和法律责任。
        </div>
        <div class="content-text">
          8.6 第三方在本平台中投放的广告、链接或者其他形式的推广内容，用户应当自行判断其真实性、准确性、完整性、有效性，本平台对该第三方推广内容不作任何明示或默示的担保。
        </div>
        <div class="content-text">
          8.7 用户理解
        </div>
        <div class="content-text">
          8.7.1 本服务同其他互联网服务一样，用户在使用服务过程中会受到网络服务质量、社会环境、用户操作错误等诸多因素的影响，可能会受到各种问题的侵扰，比如他人利用用户的资料，进行现实生活中的骚扰、诈骗。用户应加强对信息安全及使用者资料的保护意识，以免遭受损失和骚扰，因使用科研人平台服务的风险由用户自行承担。
        </div>
        <div class="content-text">
          8.7.2 本服务涉及网络服务，可能会受到各个环节不稳定因素的影响，本服务存在因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路原因等造成的服务中断或不能满足用户要求的风险。因前述情形导致用户不能发送、接受、阅读信息、或接发错信息，科研人不承担任何责任。
        </div>
        <div class="content-text">
          8.7.3 如因系统维护或升级等原因需暂停服务时，科研人将事先公告。若因硬件故障或其他不可抗力而导致暂停服务，于暂停服务期间造成的一切不便与损失，科研人不负任何责任。由于本服务的产品调整导致信息丢失和/或其他结果的，科研人不承担任何责任。
          <div class="content-text">
            8.7.4 使用本服务过程中可能存在来自第三方或其他用户的威胁性的、诽谤性的、令人反感的、匿名或冒名信息或其他非法的内容或行为和/或侵犯他人权利（包括但不限于知识产权）的风险，用户须知悉以上风险。科研人和其合作公司对本服务不作任何明示或暗示的担保，包括但不限于有关信息真实性、适于某一特定用途、所有权和非侵权性的内容，对因此导致的用户不正当或非法使用科研人服务产生的直接、间接侵犯第三人之合法权利的情况，科研人不承担任何责任。
          </div>
          <div class="content-text">
            8.7.5 科研人对于发布在科研人平台上的任何不正确或不准确的内容不承担任何责任，无论该等不准确是由科研人服务的用户所造成的，还是由于科研人服务所使用的或与科研人服务相连接的任何设备或程序所造成的。
          </div>
          <div class="content-text">
            8.7.6 用户在科研人平台上创建和发布的档案文件可能包含指向其他网站的链接。科研人平台对链接指向的网站上的内容、及其准确性或其所表达的观点不承担任何责任，并且科研人平台不对其准确性或完整性进行调查、监控或检查。在科研人服务中包含的任何链接指向的网站并不暗示科研人平台已经批准或认可所链接的网站。在访问这些第三方网站时，用户需要自己承担相关的风险。
          </div>
        </div>
        <div class="content-header">
          九、账号的限制、冻结或终止
        </div>
        <div class="content-text">
          9.1 如用户违反法律法规、本服务协议或业务协议的规定，科研人有权进行独立判断并随时限制、冻结或终止用户账户的使用，且根据实际情况决定是否恢复使用。
        </div>
        <div class="content-text">
          9.2 如果科研人发现用户并非号码初始申请注册人，科研人有权在未经通知的情况下终止用户使用该号码。
        </div>
        <div class="content-text">
          9.3 科研人按照本协议或相关法律法规，限制、冻结或终止用户账户的使用，而由此给用户带来的损失（包括但不限于通信中断，用户资料、邮件及相关数据等的清空等），由用户自行承担。
        </div>
        <div class="content-header">
          十、适用法律条款与纠纷解决
        </div>
        <div class="content-text">
          10.1 本协议的订立、效力、解释、履行和争议的解决应受中国法律的管辖，并依其解释。
        </div>
        <div class="content-text">
          10.2 在本协议执行过程中，若出现与本协议有关的争议，双方应通过友好协商解决；若任何争议无法在争议发生后十五天内通过协商解决，则任何一方有权将争议提交北京仲裁委员会通过仲裁解决。
        </div>
        <div class="content-text">
          10.3 在仲裁期间，除有争议的事项外，本协议应继续执行。
        </div>
        <div class="content-header">
          十一、违约责任
        </div>
        <div class="content-text">
          11.1 本协议任何一方违反、或没有履行其在本协议中的陈述、保证、义务或责任，即构成违约。守约方有权以书面形式通知违约方要求对违约行为进行补救。若违约方在收到上述书面通知后的十日内未采取及时、充分的补救措施，守约方有权就其因违约行为所受的损失，要求违约方进行赔偿。
        </div>
        <div class="content-header">
          十二、服务生效与终止
        </div>
        <div class="content-text">
          12.1 本协议自用户点击科研人注册页面的同意注册按钮并完成注册程序之日起生效。本协议生效后，对双方均具有法律约束力。
        </div>
        <div class="content-header">
          十三、其他
        </div>
        <div class="content-text">
          13.1科研人有权在必要时修改本协议条款。用户可以在科研人相关页面查阅本协议的最新版本。本协议条款变更后，如果用户继续使用科研人账号，即视为用户已接受修改后的协议。如果用户不接受修改后的协议，应当停止使用科研人账号。
        </div>
        <div class="content-text">
          13.2 本协议签订地为中华人民共和国北京市朝阳区。
        </div>
        <div class="content-text">
          13.3 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        </div>
        <div class="content-text">
          13.4 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
created(){
    this.navxuanzhong()
  },
  methods: {
     navxuanzhong(){
      localStorage.setItem("navActive", 0);
      this.$store.commit("setnavActive", 0);
    },
  }
}
</script>

<style lang="scss" scoped>
// 版心
.typeArea {
  width: 840px;
  padding: 80px 45px;
  box-sizing: border-box;
  margin: 0 auto;
  .top {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .top-header {
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #5e068c;
      margin-bottom: 70px;
    }
    .top-content {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
      color: #333333;
      margin-bottom: 52px;
      .content-item {
        margin-bottom: 48px;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .bottom-header {
      font-size: 35px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #5e068c;
      margin-bottom: 70px;
    }
    .bottom-content {
      .content-header {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #333333;
        margin-bottom: 36px;
      }
      .content-text {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
        margin-bottom: 36px;
      }
      .content-text-indent {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
        margin-bottom: 36px;
        text-indent: 12px;
      }
    }
  }
}
</style>
